<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

            <div class="demo-spacing-0" style="margin-left: 10px">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>罚款总金额:{{ punishAmount }}</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- 搜索、导入按钮 -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--罚款单号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="罚款单号"
                    label-for="punish_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="punish_no"
                      size="sm"
                      v-model="condition.punish_no"
                  />
                </b-form-group>
              </b-col>
              <!--仓库名称-->
              <b-col md="3">
                <modal-select
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
                    :params="['warehouse_name','warehouse_id']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name"
                >
                </modal-select>
              </b-col>
              <!--店铺名称-->
              <b-col md="3">
                <modal-select
                    label="店铺名称"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                    v-model="condition.store_name"
                >
                </modal-select>
              </b-col>
              <!--平台-->
              <b-col md="3">
                <modal-select
                    label="平台"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name','company_id']"
                    modalName="渠道方"
                    placeholder="点击选择平台"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--发生日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发生日期"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.happen_date"
                      class="form-control"
                      :config="rangeConfig"
                  />
                </b-form-group>
              </b-col>
              <!--罚款日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="罚款日期"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.punish_date"
                      class="form-control"
                      :config="rangeConfig"
                  />
                </b-form-group>
              </b-col>
              <!--运营人-->
              <b-col md="3">
                <modal-select
                    label="运营人"
                    type="input"
                    v-on:change="fromChildren($event,['business_name','business_id'])"
                    :params="['business_name','business_id']"
                    modalName="责任人"
                    placeholder="点击选择运营人"
                    v-model="condition.business_name"
                >
                </modal-select>
              </b-col>
              <!--扣款原因-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="扣款原因"
                    label-for="punish_reason"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="punish_reason"
                      :options="getCodeOptions('punish_reason')"
                      v-model="condition.punish_reason"
                      class="select-size-sm"
                      placeholder="请选择扣款原因"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--罚款单处理进度-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="罚款单处理进度"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('punish_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="请选择罚款单处理进度"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--罚款单处理结果-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="罚款单处理结果"
                    label-for="deal_result"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="deal_result"
                      :options="getCodeOptions('punish_deal_result')"
                      v-model="condition.deal_result"
                      class="select-size-sm"
                      placeholder="请选择罚款单处理结果"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- ID -->
        <template #cell(punish_id)="data">
          #{{ data.item.punish_id }}
        </template>

        <!-- 城市 -->
        <template #cell(city_id)="data">
          {{ data.item.province_name }}-{{ data.item.city_name }}
        </template>

        <!-- 发生日期 -->
        <template #cell(happen_date)="data">
          {{ toDate(data.item.happen_date) }}
        </template>

        <!-- 扣款原因 -->
        <template #cell(punish_reason)="data">
          {{ getCodeLabel('punish_reason', data.item.punish_reason) }}
        </template>

        <!-- 扣款方式 -->
        <template #cell(punish_method)="data">
          {{ getCodeLabel('punish_method', data.item.punish_method) }}
        </template>

        <!-- 罚款日期 -->
        <template #cell(punish_date)="data">
          {{ toDate(data.item.punish_date) }}
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`memo1-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.remark">
              {{ data.item.remark.substring(0, 3) }}
              <span v-if="data.item.remark.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo1-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!-- 罚款单附件 -->
        <template #cell(attachments1)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :id="data.item.attachments1"
                             :readonly="true"
          />
        </template>

        <!-- 申诉中附件 -->
        <template #cell(attachments2)="data">
          <attachment-upload v-if="data.item.attachments2 != null"
                             :theme="'preview-dropdown'"
                             :id="data.item.attachments2"
                             :readonly="true"
          />
        </template>

        <!-- 已处理附件 -->
        <template #cell(attachments3)="data">
          <attachment-upload v-if="data.item.attachments3 != null"
                             :theme="'preview-dropdown'"
                             :id="data.item.attachments3"
                             :readonly="true"
          />
        </template>

        <!-- 罚款单处理进度 -->
        <template #cell(status)="data">
          {{ getCodeLabel('punish_status', data.item.status) }}
        </template>

        <!-- 罚款单处理结果 -->
        <template #cell(deal_result)="data">
          {{ getCodeLabel('punish_deal_result', data.item.deal_result) }}
        </template>

        <!-- 处理结果备注 -->
        <template #cell(deal_remark)="data">
          <div :id="`memo2-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.deal_remark">
              {{ data.item.deal_remark.substring(0, 3) }}
              <span v-if="data.item.deal_remark.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo2-${data.item.id}`" placement="top">
            {{ data.item.deal_remark }}
          </b-tooltip>
        </template>

        <!--添加时间-->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--更新时间-->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
                v-if="(data.item.status === 1 || data.item.status === 2) && (data.item.responsibility_id === user.user_id)"
                :to="{ name: 'apps-punishorder-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 1 && (data.item.responsibility_id === user.user_id)"
                             @click="changeState(data.item)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="(data.item.status === 1 || data.item.status === 2) && (data.item.responsibility_id === user.user_id)"
                @click="showChangeModal(data.item,1)">
              <feather-icon icon="UserIcon"/>
              <span class="align-middle ml-50">变更责任人</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="(data.item.status === 1 || data.item.status === 2) && (data.item.responsibility_id === user.user_id)"
                @click="showChangeModal(data.item,2)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">申诉</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="(data.item.status === 1 || data.item.status === 2) && (data.item.responsibility_id === user.user_id)"
                @click="showChangeModal(data.item,3)">
              <feather-icon icon="NavigationIcon"/>
              <span class="align-middle ml-50">去处理</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-punishorder-edit', query: { id: data.item.id,isView:true } }">
              <feather-icon icon="EyeIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="changeModal"
        ok-title="确认"
        ok-only
        @ok="finishChange"
        centered
        size="lg"
        :title=modalTitle
    >
      <b-row>
        <!--责任人-->
        <b-col md="6" v-if="afterChangeStatus === 1">
          <modal-select
              label="责任人"
              type="input"
              v-on:change="setResponsibility($event)"
              :params="['name','id']"
              userDepartment="operator"
              modalName="责任人"
              placeholder="点击选择责任人"
              v-model="responsibilityName"
          >
          </modal-select>
        </b-col>
        <!--申诉中相关附件-->
        <b-col md="12" v-if="afterChangeStatus === 2">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="申诉中相关附件"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload :theme="'files'"
                               attachment_id="attachments2"
                               :id="attachments2"
                               object_type="punishorder"
                               :object_id="itemId"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <!--罚款单处理结果-->
        <b-col md="6" v-if="afterChangeStatus === 3">
          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label="罚款单处理结果"
              label-for="result"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="result"
                :options="getCodeOptions('punish_deal_result')"
                v-model="dealResult"
                :reduce="option => option.value"
                class="select-size-sm"
                placeholder="请选择处理结果"
            />
          </b-form-group>
        </b-col>
        <!--罚款单处理结果备注-->
        <b-col md="12" v-if="afterChangeStatus === 3">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="处理结果备注"
              label-for="deal_remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="deal_remark"
                size="sm"
                v-model="dealRemark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <!--已处理相关附件-->
        <b-col md="12" v-if="afterChangeStatus === 3">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="相关附件"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload :theme="'files'"
                               attachment_id="attachments3"
                               :id="attachments3"
                               object_type="punishorder"
                               :object_id="itemId"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import punishorderUseList from './punishorderUseList'
import punishorderStore from './punishorderStore'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import {getUserData} from "@/auth/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
    AttachmentUpload,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowCard: false,
      responsibilityId: 0,
      responsibilityName: '',
      attachments2: '',
      attachments3: '',
      dealResult: {},
      dealRemark: '',
      user: {},
      itemId: 0,
      itemNo: '',
      modalTitle: '',
      afterChangeStatus: 1,
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('punishorder')) store.registerModule('punishorder', punishorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('punishorder')) store.unregisterModule('punishorder')
    })

    const changeState = function (data) {
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('punishorder/state', {id: data.id, state: 0}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const showChangeModal = function (item, status) {
      if (status === 1) {
        this.modalTitle = '更改责任人'
      } else if (status === 2) {
        this.modalTitle = '申诉中'
      } else {
        this.modalTitle = '处理中'
      }
      this.itemId = item.punish_id
      this.itemNo = item.punish_no
      this.afterChangeStatus = status
      this.responsibilityId = item.responsibility_id
      this.responsibilityName = item.responsibility_name
      this.attachments2 = item.attachments2
      this.attachments3 = item.attachments3
      this.dealResult = getCode('punish_deal_result', item.deal_result)
      this.dealRemark = item.deal_remark
      this.$bvModal.show('changeModal')
    }

    const finishChange = function () {
      let param = {}
      param.punish_id = this.itemId
      param.punish_no = this.itemNo
      param.status = this.afterChangeStatus
      param.responsibility_id = this.responsibilityId
      param.responsibility_name = this.responsibilityName
      param.attachments2 = this.attachments2
      param.attachments3 = this.attachments3
      param.deal_result = this.dealResult
      param.deal_remark = this.dealRemark
      if (param.status === 3 && isEmpty(param.deal_result)) {
        toast.error('请选择处理结果')
        return false
      }
      store.dispatch('punishorder/finishChange', param).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '已提交!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const setResponsibility = function (param) {
      this.responsibilityId = param.id
      this.responsibilityName = param.name
      this.$forceUpdate()
    }

    const onUploaded = function (id, attachment, result) {
      this[id] = result
    }

    const exportExcel = function () {
      const params = {
        punish_no:condition.value.punish_no,
        warehouse_id:condition.value.warehouse_id,
        company_id:condition.value.company_id,
        store_id:condition.value.store_id,
        happenDate:condition.value.happen_date,
        punishDate:condition.value.punish_date,
        business_id:condition.value.business_id,
        punish_reason:condition.value.punish_reason,
        status:condition.value.status,
        deal_result:condition.value.deal_result,
      }
      store.dispatch('punishorder/exportExcel', params).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '罚款信息' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      punishAmount,

      // UI
    } = punishorderUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      advanced_search,
      fromChildren,
      searchByCondition,
      resetCondition,
      condition,
      punishAmount,
      showChangeModal,
      setResponsibility,
      finishChange,
      onUploaded,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    this.condition.punish_id = this.$route.query.id || 0;
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
